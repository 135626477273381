import * as React from 'react';
import { DropdownIndicatorProps, GroupBase } from 'react-select';
import ArrowIcon from '@assets/arrow.svg';
import * as styles from './DropdownIndicator.module.scss';


function DropdownIndicator<Option>({ innerProps }: DropdownIndicatorProps<Option, false, GroupBase<Option>>) {
  return (
    <div
      {...innerProps}
      className={styles.button}
    >
      <ArrowIcon className={styles.icon}/>
    </div>
  );
}

export default DropdownIndicator as typeof DropdownIndicator;
