import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Field, Form } from 'react-final-form';
import { toast, Id as ToastId } from 'react-toastify';
import * as api from '@core/api';
import { useUser } from '@core/hooks';
import { handleSubmissionError } from '@core/forms';
import Title from '@components/Title';
import CheckboxInput from '@components/forms/CheckboxInput';
import ButtonSubmit from '@components/forms/ButtonSubmit';
import { RouteComponentProps } from '@reach/router';
import { PostgresData, Subscription } from '@interface/gatsby';
import { API } from '@interface/common';


type FormValues = API.UpdateNewsletterPrefs.Form;

export default function NewsletterRoute(_props: RouteComponentProps) {
  const { user, updateNewsletterPrefs } = useUser();
  const { postgres } = useStaticQuery<PostgresData<Record<'subscriptions', Subscription[]>>>(graphql`
      query {
          postgres {
              subscriptions: newsletterTopicsList(orderBy: SORT_ORDER_ASC) {
                  name
                  id
                  sortOrder
              }
          }
      }
  `);
  const toastId = React.useRef<ToastId>();

  const initialValues = React.useMemo(() => ({
    subscriptions: user?.subscriptions || [],
  }), [user]);

  const handleSubmitForm = React.useCallback(async (values: FormValues): Promise<any> => {
    try {
      const response = await api.updateNewsletterPrefs(values);
      updateNewsletterPrefs(response.user.subscriptions);
      if (toastId.current) {
        toast.dismiss(toastId.current);
      }
      toastId.current = toast.success('Newsletter settings saved.');
    } catch (error) {
      toast.error(error.message);
      return handleSubmissionError(error);
    }
  }, []);

  return (
    <>
      <Title >Newsletter Subscriptions</Title>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmitForm}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            {postgres.subscriptions.map((subscription) => (
              <React.Fragment key={subscription.id}>
                <Field
                  name="subscriptions"
                  type="checkbox"
                  label={subscription.name}
                  component={CheckboxInput}
                  value={subscription.id}
                />
              </React.Fragment>
            ))}
            <ButtonSubmit label="Save"/>
          </form>
        )}
      />
    </>
  );
}
