import * as React from 'react';
import clsx from 'clsx';
import Main from '@components/Main';
import BaseCenterer from '@components/Centerer';
import * as styles from './Wrapper.module.scss';


type Props = {
  children: React.ReactNode | React.ReactNode[];
  useFullHeight?: boolean;
  useFixedWidth?: boolean;
}

export default function Wrapper({ children, useFullHeight = false, useFixedWidth = false }: Props) {
  return (
    <Main>
      <BaseCenterer
        className={clsx(styles.container, {
          [styles.fullHeight]: useFullHeight,
        })}
        withTopBarOffset
        withVerticalPadding
      >
        <div className={clsx(styles.content, {
          [styles.fixedWidth]: useFixedWidth,
        })}>
          {children}
        </div>
      </BaseCenterer>
    </Main>
  );
}
