import * as React from 'react';
import { useLocation } from '@reach/router';
import { parse, ParsedQs } from 'qs';
import { isString } from 'lodash';
import Spinner from '@components/Spinner';
import Message from '@components/Message';


type Props = {
  children: React.ReactNode | React.ReactNode[];
  api: (token: string) => Promise<any>;
  onValid?: (token: string) => any;
}

type State = {
  status: 'loading' | 'success' | 'error' | 'no_token';
  errorMessage: string | null;
};

export default function TokenValidation({ children, api, onValid }: Props) {
  const location = useLocation();
  const [state, setState] = React.useState<State>({
    status: 'loading',
    errorMessage: null,
  });

  React.useEffect(() => {
    void async function fetchData() {
      if (location?.search && location.search.startsWith('?')) {
        const query: ParsedQs = parse(location.search, { ignoreQueryPrefix: true });
        if (query?.token && isString(query.token)) {
          const token = query.token;
          try {
            await api(token);
            onValid?.(token);
            setState((state => ({ ...state, status: 'success' })));
          } catch (error) {
            setState({ status: 'error', errorMessage: error.message });
          }
        } else {
          setState((state => ({ ...state, status: 'no_token' })));
        }
      } else {
        setState((state => ({ ...state, status: 'no_token' })));
      }
    }();
  }, []);

  switch (state.status) {
    case 'success':
      return <>{children}</>;
    case 'error':
      return <Message type="error">{state.errorMessage}</Message>;
    case 'no_token':
      return <Message type="error">Invalid link provided.</Message>;
    default:
      return <Spinner/>;
  }
}
