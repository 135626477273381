// extracted by mini-css-extract-plugin
export var bundle__content = "e5144";
export var bundle__list = "_44ff2";
export var bundle__list__link = "b5872";
export var bundle__toggle = "_6320b";
export var bundle__toggle__arrow = "c094d";
export var button = "cc83f";
export var caption = "_86991";
export var container = "_41c22";
export var description = "_04aa7";
export var details = "_214b4";
export var image = "ad653";
export var inner = "_0da64";
export var title = "f9ec8";
export var withRemove = "_0b1d9";