import * as React from 'react';
import { Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import clsx from 'clsx';
import { useLibrary } from '@core/hooks';
import ImageLink from '@components/ImageLink';
import ViewButton from './ViewButton';
import UpdateIndicator from './UpdateIndicator';
import FilesIcon from '@assets/files.svg';
import { Product, ProductRelease } from '@interface/gatsby';
import * as styles from './LibraryItem.module.scss';


type Props = Pick<Product, 'id' | 'cover' | 'title' | 'url'> & {
  latestRelease: ProductRelease[];
  onViewClick: (id: string) => void;
};

export default function LibraryItem({ id, cover, title, url, latestRelease, onViewClick }: Props) {
  const { hasUpdate } = useLibrary();
  const image = getImage(cover);
  const release = latestRelease.length > 0 ? latestRelease[0] : null;
  const isUpdateAvailable = hasUpdate(id);

  const handleViewClick = () => onViewClick(id);

  return (
    <article className={styles.container}>
      <ImageLink
        image={image}
        alt={title}
        label="View files"
        onClick={handleViewClick}
      />
      <div className={styles.caption}>
        <div className={styles.caption__details}>
          <h3 className={styles.caption__details__title}>
            <Link to={url} data-text={title}>
              <span>{title}</span>
            </Link>
          </h3>
          <small className={styles.caption__details__description}>
            {`v${release ? release.version : '1.0.0'} / ${release?.createdAt}`}
          </small>
        </div>
        <div className={styles.caption__controls}>
          <ViewButton
            className={clsx('button small light', styles.caption__controls__button)}
            onClick={handleViewClick}
          >
            <FilesIcon className={styles.caption__controls__button__icon}/>
          </ViewButton>
        </div>
      </div>
      <UpdateIndicator isActive={isUpdateAvailable}/>
    </article>
  );
}
