import * as React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { pick } from 'lodash';
import clsx from 'clsx';
import BaseInput, { BaseInputProps } from './BaseInput';
import { FieldRenderProps } from '@interface/common';
import { HTMLAttributes } from 'react';

type Props =
  FieldRenderProps<string> &
  Pick<BaseInputProps, 'useLabel' | 'fieldClassName'> &
  HTMLAttributes<HTMLInputElement> &
  {
    minRows?: number;
    maxRows?: number;
    useCount?: boolean;
  }

export default function TextArea({
  input,
  meta,
  id,
  label,
  required,
  maxLength = 255,
  minRows = 5,
  maxRows,
  useCount,
  className,
  fieldClassName,
  ...props
}: Props) {
  const componentId = id || React.useId();
  const inputProps = pick(props, ['autoComplete', 'autoCapitalize', 'autoCorrect', 'spellCheck']);

  return (
    <BaseInput
      id={componentId}
      label={label}
      meta={meta}
      fieldClassName={fieldClassName}
      required={required}
      renderSubLabel={() => useCount && (
        <span className="form__fieldSubLabel">
          {input.value.length} / {maxLength}
        </span>
      )}
    >
      <TextareaAutosize
        {...input}
        {...inputProps}
        className={clsx('form__fieldInput', className)}
        id={componentId}
        minRows={minRows}
        maxRows={maxRows}
        cacheMeasurements={true}
        maxLength={maxLength}
        required={required}
      />
      <span className="form__pseudo"/>
    </BaseInput>
  );
}
