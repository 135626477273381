import * as React from 'react';
import { isEqual } from 'lodash';
import { ListItemProps, AnyObject } from '@interface/common';


function EntitiesList<Type>({
    ids,
    entities,
    render,
  }: {
    ids?: string[],
    entities: AnyObject<Type>,
    render: string | React.FunctionComponent<ListItemProps<Type>> | React.ComponentClass<ListItemProps<Type>, any>,
  },
) {
  const componentId = React.useId();
  const items = ids || Object.keys(entities);
  return (
    <>
      {items.map((id, index) => {
        let item: Type = entities[id];
        if (!item) return null;
        return (
          <React.Fragment key={`${componentId}-${id}`}>
            {React.createElement(render, { item, index })}
          </React.Fragment>
        );
      })}
    </>
  );
}

export default React.memo(
  EntitiesList,
  ((prevProps, nextProps) => {
    return isEqual(prevProps.ids, nextProps.ids) && isEqual(prevProps.entities, nextProps.entities);
  }),
) as typeof EntitiesList;
