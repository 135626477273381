import * as React from 'react';
import Select, { SingleValue } from 'react-select';
import BaseInput from './BaseInput';
import { BaseInputProps } from '@components/forms/BaseInput';
import DropdownIndicator from './select/DropdownIndicator';
import ClearIndicator from './select/ClearIndicator';
import { FieldRenderProps } from '@interface/common';


type Option = {
  id: string;
  name: string;
}

type Props = FieldRenderProps<Option> &
  Pick<BaseInputProps, 'useLabel' | 'fieldClassName'> & {
  options: Option[]
}

export default function SelectInput({
  input,
  meta,
  options,
  id,
  label,
  required,
  useLabel = true,
  fieldClassName,
}: Props) {
  const componentId = id || React.useId();
  const getOptionLabel = (option: Option) => option.name;
  const getOptionValue = (option: Option) => option.id;

  const handleSelectChange = (option: SingleValue<Option>) => input.onChange(option);

  return (
    <BaseInput
      id={componentId}
      label={label}
      meta={meta}
      required={required}
      useLabel={useLabel}
      fieldClassName={fieldClassName}
    >
      <Select<Option>
        id={componentId}
        classNamePrefix="select"
        name={input.name}
        defaultValue={input.value}
        options={options}
        isClearable
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        onChange={handleSelectChange}
        components={{
          ClearIndicator,
          IndicatorSeparator: undefined,
          DropdownIndicator,
        }}
      />
    </BaseInput>
  );
}
