import * as React from 'react';
import { Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { isUndefined } from 'lodash';
import clsx from 'clsx';
import { CART } from '@core/constants';
import { useCart } from '@core/hooks';
import Price from '@components/Price';
import EntitiesList from '@components/EntitiesList';
import ImageLink from '@components/ImageLink';
import RemoveButton from '@components/RemoveButton';
import { Bundle, Product, ProductCategory } from '@interface/gatsby';
import { AnyObject, CartItemType } from '@interface/common';
import * as styles from './ProductRow.module.scss';


type Props = Pick<Product | Bundle, 'id' | 'cover' | 'title' | 'url' | 'price'> & {
  className?: string;
  type?: CartItemType;
  ids?: string[],
  products?: AnyObject<Product>;
  category?: ProductCategory;
  discount?: number;
  showDiscount?: boolean;
  disabled?: boolean;
  expanded?: boolean;
  onExpand?: (id: string) => void;
  onRemove?: (id: string, type: CartItemType) => void;
}

export default function ProductRow({
  className,
  id,
  cover,
  title,
  url,
  category,
  price,
  discount,
  type = CART.ITEM.PRODUCTS,
  ids,
  products,
  disabled = false,
  expanded = false,
  showDiscount = true,
  onExpand,
  onRemove,
}: Props) {
  const { getDiscount } = useCart();
  const image = getImage(cover);
  const discountValue = showDiscount ? (discount ? discount : getDiscount(id)) : 0;

  const handleRemoveClick = () => onRemove?.(id, type);

  const handleExpandClick = () => onExpand?.(id);

  return (
    <article className={clsx(styles.container, className)}>
      <section className={clsx(styles.inner, { [styles.withRemove]: !isUndefined(onRemove) })}>
        <ImageLink
          className={styles.image}
          to={url}
          image={image}
          alt={title}
          label="View"
          disabled={disabled}
        />
        <div className={styles.caption}>
          <div className={styles.details}>
            <h4 className={styles.title}>
              <Link
                to={url}
                className={clsx({ 'disabled': disabled })}
                data-text={title}
              >
                <span>{title}</span>
              </Link>
            </h4>
            <p className={styles.description}>
              {type === CART.ITEM.BUNDLES ?
                <button
                  className={styles.bundle__toggle}
                  type="button"
                  onClick={handleExpandClick}
                >
                  {'Bundle '}<span className={styles.bundle__toggle__arrow}>{expanded ? '▴' : '▾'}</span>
                </button> :
                category!.description
              }
            </p>
          </div>
          <div>
            <Price
              value={price}
              discount={discountValue}
              showDiscount={showDiscount}
              showFree
              useStyling
            />
          </div>
        </div>
        {!isUndefined(onRemove) && (
          <RemoveButton
            className={styles.button}
            disabled={disabled}
            onClick={handleRemoveClick}
          />
        )}
      </section>
      {type === CART.ITEM.BUNDLES && expanded && (
        <section className={styles.bundle__content}>
          <ul className={styles.bundle__list}>
            <EntitiesList
              ids={ids}
              entities={products!}
              render={({ item }) => (
                <li>
                  <Link
                    className={clsx(styles.bundle__list__link, { 'disabled': disabled })}
                    to={item.url}
                  >
                    {item.title}
                  </Link>
                </li>
              )}
            />
          </ul>
        </section>
      )}
    </article>
  );
}
