import * as React from 'react';
import { Link } from 'gatsby';
import * as api from '@core/api';
import { useEmailConfirmation } from '@core/hooks';
import Wrapper from '@components/Wrapper';
import Message from '@components/Message';
import TokenValidation from '@components/auth/TokenValidation';
import { RouteComponentProps } from '@reach/router';
import * as styles from './confirm_email.module.scss';


export default function ConfirmEmailRoute({}: RouteComponentProps) {
  const confirmation = useEmailConfirmation();

  const handleEmailValid = React.useCallback(() => {
    confirmation?.notify({ isEmailConfirmed: true });
  }, [confirmation]);

  return (
    <Wrapper
      useFixedWidth
      useFullHeight
    >
      <TokenValidation
        api={api.confirmEmail}
        onValid={handleEmailValid}
      >
        <Message type="success">
          Email is successfully confirmed.
        </Message>
        <div className={styles.controls}>
          <Link to="/" className="button small light">
            Return to Home page
          </Link>
        </div>
      </TokenValidation>
    </Wrapper>
  );
}
