import * as React from 'react';
import clsx from 'clsx';
import { isFunction } from 'lodash';
import { isError } from '@core/forms';
import BaseInput from './BaseInput';
import { FieldRenderProps } from '@interface/common';
import * as styles from './CheckboxInput.module.scss';


export default function CheckboxInput({
  input,
  meta,
  label,
  required,
  ...props
}: FieldRenderProps<string>) {
  const componentId = React.useId();
  const hasError = isError(meta);
  return (
    <BaseInput
      id={componentId}
      meta={meta}
      required={required}
      useLabel={false}
    >
      <label
        className={styles.toggle}
        htmlFor={componentId}
      >
        <input
          {...input}
          {...props}
          type="checkbox"
          className={styles.input}
          id={componentId}
          checked={input.checked}
          required={required}
        />
        <span className={clsx(styles.track, { [styles.hasError]: hasError })}>
          <span className={styles.indicator}/>
        </span>
        <span className={styles.label}>{isFunction(label) ? label() : label}</span>
      </label>
    </BaseInput>
  );
}
