import * as React from 'react';
import * as styles from './Title.module.scss';


export type Props = {
  children: string;
};

export default function Title({ children }: Props) {
  return <h1 id="heading" className={styles.title}>{children}</h1>
}
