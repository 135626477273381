import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useRecoilValue } from 'recoil';
// @ts-ignore
import InfiniteScroll from 'react-infinite-scroller';
import { ordersStatsState } from '@core/atoms';
import { useEntities, useLibrary, useRoutes } from '@core/hooks';
import Title from '@components/Title';
import Spinner from '@components/Spinner';
import Info from '@components/Info';
import EntitiesList from '@components/EntitiesList';
import OrderRow from '@components/account/OrderRow';
import { RouteComponentProps } from '@reach/router';
import { PostgresData, Product, Bundle } from '@interface/gatsby';
import { Order } from '@interface/common';
import * as styles from './order_history.module.scss';


type DataType = PostgresData<Record<'products', Product[]> & Record<'bundles', Bundle[]>>;

export default function OrdersHistoryRoute(_props: RouteComponentProps) {
  const componentId = React.useId();
  const { fetchOrders, loadMoreOrders } = useLibrary();
  const { route, PRODUCTS } = useRoutes();
  const { ids, entities, isFetching, isEmpty, notFound, hasMore } = useRecoilValue(ordersStatsState);
  const { postgres } = useStaticQuery<DataType>(graphql`
      query {
          postgres {
              products: productsList {
                  id
                  title
                  slug
                  url
                  cover {
                      childImageSharp {
                          gatsbyImageData(layout: CONSTRAINED)
                      }
                  }
                  category {
                      slug
                      description
                  }
              }
              bundles: bundlesList(orderBy: CREATED_AT_DESC) {
                  id
                  title
                  slug
                  url
                  cover {
                      childImageSharp {
                          gatsbyImageData(layout: CONSTRAINED)
                      }
                  }
              }
          }
      }
  `);
  const initial = React.useRef(true);

  React.useEffect(() => {
    (async () => {
      if (isEmpty) {
        await fetchOrders();
        initial.current = false;
      }
    })();
  }, []);

  const products = useEntities<Product>(postgres.products);
  const bundles = useEntities<Bundle>(postgres.bundles);

  const isInitialFetching = initial.current && isFetching;

  return (
    <>
      <Title>Purchase History</Title>
      <div className={styles.overflow}>
        {isInitialFetching && <Spinner className={styles.initial} size="small"/>}
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMoreOrders}
          hasMore={hasMore}
          loader={<Spinner key={`${componentId}-spinner`} size="small"/>}
        >
          {!isFetching && notFound && (
            <Info
              title="No purchased products found."
              level={4}
              linkUrl={route(PRODUCTS)}
              linkText="Explore products"
              key={`${componentId}-empty`}
            />
          )}
          <EntitiesList<Order>
            key={1}
            ids={ids}
            entities={entities}
            render={({ item }) => (
              <OrderRow
                {...item}
                products={products}
                bundles={bundles}
              />
            )}
          />
        </InfiniteScroll>
      </div>
    </>
  );
}
