import * as React from 'react';
import { isArray } from 'lodash';
import { useCountDown, useUser, useRoutes } from '@core/hooks';
import { POSITION } from '@core/constants';
import { isBrowser } from '@core/utils/env';
import Main from '@components/Main';
import Centerer from '@components/Centerer';
import Spinner from '@components/Spinner';
import Sidebar from '@components/Sidebar';
import Content from '@components/Content';
import AccountMenu from '@components/account/AccountMenu';
import NotFoundPage from '@pages/404';
import { RouteComponentProps } from '@reach/router';
import { GlobalProps } from '@interface/common';
import * as styles from './index.module.scss';


type Child = React.ReactElement<any, string | React.JSXElementConstructor<any>>;

type Props = Pick<RouteComponentProps, 'path' | 'location'> & Pick<GlobalProps, 'isAuthenticating'> & {
  children: Child | Child[];
}

export default function ProfileLayout({ location, children }: Props) {
  const { isAuthenticated } = useUser();
  const { routes } = useRoutes();

  useCountDown(true);

  if (isBrowser && !routes.private.includes(location!.pathname)) {
    return <NotFoundPage/>;
  }

  return (
    !isAuthenticated ?
      <Spinner className={styles.spinner}/> :
      <Main>
        <Centerer
          withTopBarOffset
          withVerticalPadding
          sidebar={POSITION.LEFT}
        >
          <Sidebar hideOnMobile>
            <nav className={styles.nav}>
              <AccountMenu activeClassName={styles.nav__linkActive}/>
            </nav>
          </Sidebar>
          <Content>
            {!isArray(children) ? React.cloneElement(children, { component: React.Fragment }) : null}
          </Content>
        </Centerer>
      </Main>
  );
}
