import * as React from 'react';
import { Router } from '@reach/router';
import { LABEL } from '@core/constants';
import { useRoutes } from '@core/hooks';
import ProfileLayout from '@layouts/ProfileLayout';
import ConfirmEmailRoute from '@routes/confirm_email';
import ResetPasswordRoute from '@routes/reset_password';
import ProfileRoute from '@routes/profile';
import OrdersHistoryRoute from '@routes/orders_history';
import NewsletterRoute from '@routes/newsletter';
import SecurityRoute from '@routes/security';
import LibraryRoute from '@routes/library';
import NotFoundRoute from '@pages/404';
import BaseHead from '@components/Head';
import { PageProps } from '@interface/common';


export default function AccountPage({ isAuthenticating }: PageProps<any>) {
  const routes = useRoutes();

  const layoutProps = { isAuthenticating };

  return (
    // @ts-ignore
    <Router basepath={routes._ACCOUNT_BASE} primary={false} component={React.Fragment}>
      <NotFoundRoute default/>
      <ConfirmEmailRoute path={routes.CONFIRM_EMAIL}/>
      <ResetPasswordRoute path={routes.RESET_PASSWORD}/>
      <ProfileLayout {...layoutProps} path="/">
        <LibraryRoute path="/"/>
        <OrdersHistoryRoute path={routes.ORDERS_HISTORY}/>
        <ProfileRoute path={routes.PROFILE}/>
        <SecurityRoute path={routes.SECURITY}/>
        <NewsletterRoute path={routes.NEWSLETTER}/>
      </ProfileLayout>
    </Router>
  );
}

export function Head({ params }: PageProps<any>) {
  const { clean, CONFIRM_EMAIL, RESET_PASSWORD, ORDERS_HISTORY, PROFILE, SECURITY, NEWSLETTER } = useRoutes();

  let title;
  switch (params['*']) {
    case '':
      title = LABEL.LIBRARY;
      break;
    case clean(CONFIRM_EMAIL):
      title = LABEL.CONFIRM_EMAIL;
      break;
    case clean(RESET_PASSWORD):
      title = LABEL.RESET_PASSWORD;
      break;
    case clean(ORDERS_HISTORY):
      title = LABEL.ORDERS_HISTORY;
      break;
    case clean(PROFILE):
      title = LABEL.PROFILE;
      break;
    case clean(SECURITY):
      title = LABEL.SECURITY;
      break;
    case clean(NEWSLETTER):
      title = LABEL.NEWSLETTER;
      break;
    default:
      title = LABEL.NOT_FOUND;
      break;
  }

  return <BaseHead title={title}/>;
}
