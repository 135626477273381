import * as React from 'react';
import { ClearIndicatorProps, GroupBase } from 'react-select';
import clsx from 'clsx';
import PlusIcon from '@assets/plus.svg';
import * as styles from './ClearIndicator.module.scss';


function ClearIndicator<Option>({ innerProps }: ClearIndicatorProps<Option, false, GroupBase<Option>>) {
  return (
    <div
      {...innerProps}
      className={clsx('form__fieldReset', styles.button)}
    >
      <PlusIcon/>
    </div>
  );
}

export default ClearIndicator as typeof ClearIndicator;
