import * as React from 'react';
import { navigate } from 'gatsby';
import { Form, Field } from 'react-final-form';
import { toast } from 'react-toastify';
import * as api from '@core/api';
import { LABEL } from '@core/constants';
import { useUser, useCart, useRoutes } from '@core/hooks';
import Validation, {
  focusOnErrorDecorator,
  handleSubmissionError,
  isEqualToField,
  isStrongPassword,
} from '@core/forms';
import Wrapper from '@components/Wrapper';
import Title from '@components/auth/Title';
import TokenValidation from '@components/auth/TokenValidation';
import PasswordInput from '@components/forms/PasswordInput';
import ButtonSubmit from '@components/forms/ButtonSubmit';
import { RouteComponentProps } from '@reach/router';
import { API } from '@interface/common';


const FORM_ID = 'reset-password-form';

const schema = new Validation({
  password: {
    required: true,
    use: { isStrongPassword },
  },
  passwordConfirmation: {
    required: true,
    use: {
      isEqualToField: (...args: any[]) => isEqualToField('password', ...args),
    },
  },
});

const focusOnError = focusOnErrorDecorator(FORM_ID);

export default function ResetPasswordRoute(_props: RouteComponentProps) {
  const { signIn } = useUser();
  const { getCartData } = useCart();
  const { route, ACCOUNT } = useRoutes();
  let token = React.useRef<string>();

  const handleValidateForm = React.useCallback((values: API.ResetPassword.Form) => schema.validate(values), []);

  const handleSubmitForm = React.useCallback(async (values: API.ResetPassword.Form): Promise<any> => {
    try {
      const response = await api.resetPassword(values, token.current!, getCartData());
      signIn(response);
      await navigate?.(route(ACCOUNT));
    } catch (error) {
      toast.error(error.message);
      return handleSubmissionError(error);
    }
  }, [getCartData]);

  return (
    <Wrapper
      useFixedWidth
      useFullHeight
    >
      <TokenValidation
        api={api.validatePasswordResetToken}
        onValid={(value) => token.current = value}
      >
        <Title>{LABEL.RESET_PASSWORD}</Title>
        <Form<API.ResetPassword.Form>
          validate={handleValidateForm}
          // @ts-ignore
          decorators={[focusOnError]}
          onSubmit={handleSubmitForm}
          render={({ handleSubmit }) => (
            <form
              id={FORM_ID}
              onSubmit={handleSubmit}
              noValidate
            >
              <Field
                name="password"
                type="password"
                label="New Password"
                component={PasswordInput}
                useToggle
                useMeter
                useHint
                required
              />
              <Field
                name="passwordConfirmation"
                type="password"
                label="Confirm Password"
                component={PasswordInput}
                useToggle
                required
              />
              <ButtonSubmit
                label="Reset password"
                stretch
              />
            </form>
          )}
        />
      </TokenValidation>
    </Wrapper>
  );
}
