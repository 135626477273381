import * as React from 'react';
import clsx from 'clsx';
import Title from '@components/Title';
import Link from '@components/Link';
import * as styles from './Info.module.scss';


type Props = {
  title: string;
  linkUrl?: string;
  linkText?: string;
  level?: 1 | 2 | 3 | 4;
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
}

export default function Info({ className, children, title, linkUrl, linkText, level }: Props) {
  return (
    <div className={clsx(styles.content, className)}>
      <Title
        className={styles.title}
        level={level}
        noMargin
      >
        {title}
      </Title>
      {children && (
        <div className="format text__align--center">
          {children}
        </div>
      )}
      {linkUrl && (
        <Link
          to={linkUrl}
          className={clsx('button small light', styles.link)}
        >
          {linkText}
        </Link>
      )}
    </div>
  );
}
