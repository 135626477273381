import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Form, Field } from 'react-final-form';
import { toast, Id as ToastId } from 'react-toastify';
import { omit, isUndefined } from 'lodash';
import { useUser, useCountDown, useRoutes, useEmailConfirmation } from '@core/hooks';
import * as api from '@core/api';
import Validation, { focusOnErrorDecorator, handleSubmissionError, isEmail, parseNullFieldValue } from '@core/forms';
import Title from '@components/Title';
import Message from '@components/Message';
import Link from '@components/Link';
import TextInput from '@components/forms/TextInput';
import PasswordInput from '@components/forms/PasswordInput';
import SelectInput from '@components/forms/SelectInput';
import TextArea from '@components/forms/TextArea';
import ButtonSubmit from '@components/forms/ButtonSubmit';
import { RouteComponentProps } from '@reach/router';
import { PostgresData, Country } from '@interface/gatsby';
import { API } from '@interface/common';


const FORM_ID = 'profile-form';

const schema = new Validation({
  name: {
    required: true,
  },
  email: {
    required: true,
    use: { isEmail },
  },
});

const focusOnError = focusOnErrorDecorator(FORM_ID);

type FormValues = Omit<API.UpdateProfile.Form, 'countryId'> & {
  country?: Country;
}

export default function ProfileRoute(_props: RouteComponentProps) {
  const { postgres } = useStaticQuery<PostgresData<Record<'countries', Country>>>(graphql`
      query {
          postgres {
              countries: countriesList(orderBy: NAME_ASC) {
                  id
                  name
              }
          }
      }
  `);
  const { user, getProfile, updateProfile } = useUser();
  const { route, SECURITY } = useRoutes();
  const worker = useCountDown();
  const toastId = React.useRef<ToastId>();

  useEmailConfirmation(true);

  const initialValues = React.useMemo(getProfile, [user]);

  const handleValidateCurrentPassword = React.useCallback((value: string) => {
    return isUndefined(value) ? schema.getMessage('required') : undefined;
  }, []);

  const handleValidateForm = React.useCallback((values: FormValues) => schema.validate(values), []);

  const handleSubmitForm = React.useCallback(async (values: FormValues): Promise<any> => {
    try {
      const params = {
        ...omit(values, ['country']),
        countryId: values.country?.id || null,
      };
      const response = await api.updateProfile(params);
      if (response.user.email !== user!.email) {
        worker?.start();
      }
      updateProfile(response.user);
      if (toastId.current) {
        toast.dismiss(toastId.current);
      }
      toastId.current = toast.success('Profile data saved.');

    } catch (error) {
      toast.error(error.message);
      if (error.errors) {
        return error.errors;
      } else {
        return handleSubmissionError(error);
      }
    }
  }, [user]);

  return (
    <>
      <Title>Profile</Title>
      <Form<FormValues>
        initialValues={initialValues}
        validate={handleValidateForm}
        // @ts-ignore
        decorators={[focusOnError]}
        onSubmit={handleSubmitForm}
        render={({ handleSubmit, dirtyFields }) => (
          <form
            id={FORM_ID}
            className="form--constrained"
            onSubmit={handleSubmit}
            noValidate
          >
            <Field
              name="name"
              type="text"
              label="Full name"
              placeholder="John Doe"
              autoComplete="new-password"
              autoCapitalize="words"
              autoCorrect="off"
              spellCheck="false"
              component={TextInput}
              maxLength={100}
              required
            />
            <Field
              name="email"
              type="email"
              label="Email"
              placeholder="john@domain.com"
              autoComplete="off"
              component={TextInput}
              useReset
              required
            />
            {dirtyFields.email && (
              user?.isPasswordSet?
                <Field
                  name="currentPassword"
                  type="password"
                  label="Enter your password to change email address"
                  autoComplete="new-password"
                  component={PasswordInput}
                  validate={handleValidateCurrentPassword}
                  useToggle
                  required
                /> :
                <Message type="warning">
                  <span className="format">
                    {'Set '}
                    <Link to={route(SECURITY)} external>Password</Link>
                    {' to protect your account and be able to pass extra security check to change your email.'}
                  </span>
                </Message>
            )}
            <Field
              name="country"
              label="Country"
              options={postgres.countries}
              component={SelectInput}
            />
            <Field
              name="company"
              type="text"
              label="Company"
              autoComplete="off"
              autoCapitalize="words"
              autoCorrect="off"
              spellCheck="false"
              component={TextInput}
              parse={parseNullFieldValue}
              maxLength={60}
            />
            <Field
              name="bio"
              label="Bio"
              autoComplete="off"
              autoCapitalize="sentences"
              autoCorrect="off"
              spellCheck="true"
              component={TextArea}
              parse={parseNullFieldValue}
              maxLength={400}
              useCount
            />
            <ButtonSubmit label="Save"/>
          </form>
        )}
      />
    </>
  );
}
