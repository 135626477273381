import * as React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import clsx from 'clsx';
import ImagePlaceholder from '@assets/no-image.svg';
import * as styles from './ImageLink.module.scss';


type Props = {
  to?: string;
  image?: IGatsbyImageData;
  alt: string;
  label: string;
  disabled?: boolean;
  className?: string;
  imageClassName?: string;
  useOverlay?: boolean;
  onClick?: () => void;
};

export default function ImageLink({
  to,
  image,
  alt,
  label,
  disabled = false,
  useOverlay = true,
  className,
  imageClassName,
  onClick,
}: Props) {
  const props = {
    className: clsx(styles.link, className, { 'disabled': disabled }),
  };

  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    onClick?.();
  }

  const _renderImage = () => image ?
    <>
      <GatsbyImage
        className={imageClassName}
        image={image}
        alt={alt}
      />
      {useOverlay && (
        <span className={styles.overlay}>
          <span className={styles.button}>{label}</span>
        </span>
      )}
    </> :
    <ImagePlaceholder className={styles.placeholder}/>
  ;

  return (
    to ?
      <Link {...props} to={to}>{_renderImage()}</Link> :
      <a {...props} href="#" onClick={handleLinkClick}>{_renderImage()}</a>
  );
}
