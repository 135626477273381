import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { defaults } from 'lodash';
import { parse, stringify } from 'qs';
import { useRecoilValue } from 'recoil';
import { libraryStatsState, updatesStatsState } from '@core/atoms';
import { FILTER } from '@core/constants';
import { useEntities, useLibrary, useRoutes } from '@core/hooks';
import Title from '@components/Title';
import Info from '@components/Info';
import LibraryFilter from '@components/library/LibraryFilter';
import LibraryExplorer from '@components/library/LibraryExplorer';
import UnconfirmedEmailMessage from '@components/account/UnconfirmedEmailMessage';
import { RouteComponentProps } from '@reach/router';
import { PostgresData, Product } from '@interface/gatsby';
import * as styles from './library.module.scss';


type FilterData = {
  show: 'all' | string;
  search: string;
}

export default function LibraryRoute({ location, uri, navigate }: RouteComponentProps) {
  const { postgres } = useStaticQuery<PostgresData<Record<'products', Product[]>>>(graphql`
      query LibraryProducts {
          postgres {
              products: productsList(orderBy: CREATED_AT_DESC) {
                  id
                  title
                  slug
                  url
                  cover {
                      childImageSharp {
                          gatsbyImageData(layout: CONSTRAINED)
                      }
                  }
                  category {
                      id
                      slug
                      description
                  }
                  latestRelease: productReleasesList(orderBy: CREATED_AT_DESC, first: 1) {
                      id
                      version
                      platforms
                      supports
                      changes
                      createdAt
                      files: productFilesByReleaseIdList(orderBy: [TARGET_ASC, NAME_DESC]) {
                          id
                          name
                          description
                          target
                          size
                          supports
                      }
                  }
              }
          }
      }
  `);
  const library = useRecoilValue(libraryStatsState);
  const updates = useRecoilValue(updatesStatsState);
  const defaultSearchParams = { show: FILTER.SHOW_ALL, search: '' };
  const query: FilterData = location?.search ?
    defaults(parse(location.search, { ignoreQueryPrefix: true }), defaultSearchParams) :
    defaultSearchParams;

  const products = useEntities<Product>(postgres.products);
  const { checkUpdates, filterIds } = useLibrary();
  const { route, PRODUCTS } = useRoutes();

  React.useEffect(() => {
    (async () => !library.isEmpty && updates.notFound && await checkUpdates())();
  }, []);

  const ids = React.useMemo<string[]>(() => {
    return filterIds(products, query.show, query.search);
  }, [query]);

  const updateUrl = (data: Pick<FilterData, 'show'> | Pick<FilterData, 'search'>) => {
    navigate?.(uri! + stringify({ ...query, ...data }, { addQueryPrefix: true }));
  };

  const handleFilterChange = (show: string) => updateUrl({ show });

  const handleSearchChange = (search: string) => (search !== query.search) && updateUrl({ search });

  return (
    <>
      <Title>Library</Title>
      <UnconfirmedEmailMessage/>
      <LibraryFilter
        show={query.show}
        search={query.search}
        onFilterChange={handleFilterChange}
        onSearchChange={handleSearchChange}
      />
      <div className={styles.grid}>
        <LibraryExplorer
          ids={ids}
          products={products}
          renderNoResults={() => (
            <Info
              className={styles.empty}
              title="No purchased products found."
              level={4}
              linkUrl={route(PRODUCTS)}
              linkText="Explore products"
            />
          )}
        />
      </div>
    </>
  );
}
