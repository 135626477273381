import * as React from 'react';
import { Form, Field } from 'react-final-form';
import { FormApi } from 'final-form';
import { getIn } from 'final-form';
import { toast, Id as ToastId } from 'react-toastify';
import createDecorator, { FindInput, FocusableInput } from 'final-form-focus';
import * as api from '@core/api';
import { useUser } from '@core/hooks';
import Validation, { isEqualToField, isStrongPassword, handleSubmissionError } from '@core/forms';
import Title from '@components/Title';
import Message from '@components/Message';
import PasswordInput from '@components/forms/PasswordInput';
import ButtonSubmit from '@components/forms/ButtonSubmit';
import { RouteComponentProps } from '@reach/router';
import { AnyObject, API } from '@interface/common';


const FORM_ID = 'security-form';

let isFocusOnErrorEnabled = true;
const findInput: FindInput = (inputs: FocusableInput[], errors: AnyObject) => {
  const result = isFocusOnErrorEnabled ? inputs.find(input => {
    // @ts-ignore
    let form = input.closest('form');
    if (form?.id === FORM_ID) {
      return input.name && getIn(errors, input.name);
    }
  }) : undefined;
  isFocusOnErrorEnabled = true;
  return result;
};
const focusOnError = createDecorator(undefined, findInput);


type FormValues = API.SetPassword.Form & API.UpdatePassword.Form;

export default function SecurityRoute(_props: RouteComponentProps) {
  const { user, markPasswordAsSet } = useUser();
  const toastId = React.useRef<ToastId>();

  let rules: AnyObject = {
    password: {
      required: true,
      use: { isStrongPassword },
    },
    passwordConfirmation: {
      required: true,
      use: {
        isEqualToField: (...args: any[]) => isEqualToField('password', ...args),
      },
    },
  };

  if (user?.isPasswordSet) {
    rules.currentPassword = {
      required: true,
    };
  }

  const schema = new Validation(rules);

  const handleValidateForm = React.useCallback((values: FormValues) => schema.validate(values), [schema]);

  const handleSubmitForm = React.useCallback(async (values: FormValues, form: FormApi<FormValues>) => {
    try {
      if (user?.isPasswordSet) {
        await api.updatePassword(values);
      } else {
        const response = await api.setPassword(values);
        markPasswordAsSet(response.user.isPasswordSet);
      }
      isFocusOnErrorEnabled = false;
      form.restart();
      if (toastId.current) {
        toast.dismiss(toastId.current);
      }
      toastId.current = toast.success('Security settings saved.');
      return;
    } catch (error) {
      toast.error(error.message);
      return handleSubmissionError(error);
    }
  }, []);

  return (
    <>
      <Title>Security Settings</Title>
      {!user?.isPasswordSet && (
        <div className="form--constrained">
          <Message type="info">
            Set a password to protect your account.
          </Message>
        </div>
      )}
      <Form
        validate={handleValidateForm}
        // @ts-ignore
        decorators={[focusOnError]}
        onSubmit={handleSubmitForm}
        render={({ handleSubmit }) => (
          <form
            id={FORM_ID}
            className="form--constrained"
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
          >
            {user?.isPasswordSet && (
              <Field
                name="currentPassword"
                type="password"
                label="Current password"
                autoComplete="new-password"
                component={PasswordInput}
                useToggle
                required
              />
            )}
            <Field
              name="password"
              type="password"
              label={`${user?.isPasswordSet ? 'New p' : 'P'}assword`}
              autoComplete="new-password"
              component={PasswordInput}
              useToggle
              useMeter
              useHint
              required
            />
            <Field
              name="passwordConfirmation"
              type="password"
              label={`Confirm ${user?.isPasswordSet ? 'new ' : ''}password`}
              autoComplete="new-password"
              component={PasswordInput}
              useToggle
              required
            />
            <ButtonSubmit label="Save"/>
          </form>
        )}
      />
    </>
  );
}
