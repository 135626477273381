import * as React from 'react';
import Spinner from '@components/Spinner';
import EntitiesList from '@components/EntitiesList';
import ProductView, { ProductViewParams } from '@components/store/ProductView';
import LibraryItem from './LibraryItem';
import { Product } from '@interface/gatsby';
import { AnyObject } from '@interface/common';


type State = {
  isOpen: boolean;
  productId: string | null;
}

type Props = {
  ids: string[];
  products: AnyObject<Product>;
  isLoading?: boolean;
  isEmpty?: boolean;
  renderNoResults?: () => React.ReactNode;
}

export default function LibraryExplorer({ ids, products, isLoading = false, renderNoResults }: Props) {
  const [state, setState] = React.useState<State>({ isOpen: false, productId: null });

  const viewedProduct = React.useMemo<ProductViewParams | null>(() => {
    if (!state.productId) return null;
    let product = products[state.productId];
    let latestRelease = product.latestRelease.length > 0 ? product.latestRelease[0] : null;
    if (latestRelease) {
      const { id, title, cover, category } = product;
      const { version, createdAt, files } = latestRelease;
      return {
        id,
        title,
        description: category.description,
        cover,
        version,
        createdAt,
        files,
      };
    }
    return null;
  }, [state.productId]);

  const handleViewOpen = (productId: string) => setState({ productId, isOpen: true });

  const handleViewClose = () => setState(prevState => ({ ...prevState, isOpen: false }));

  return (
    <>
      {isLoading ?
        <Spinner size="small"/> :
        <>
          {ids.length === 0 && renderNoResults?.()}
          <EntitiesList<Product>
            ids={ids}
            entities={products}
            render={({ item }) => (
              <LibraryItem {...item} onViewClick={handleViewOpen}/>
            )}
          />
        </>
      }
      {/* @ts-ignore */}
      <ProductView
        {...viewedProduct}
        isOpen={state.isOpen}
        onRequestClose={handleViewClose}
      />
    </>
  );
}
