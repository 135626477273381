import * as React from 'react';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import * as api from '@core/api';
import { useUser, useCountDown, useEmailConfirmation } from '@core/hooks';
import Message from '@components/Message';
import TimerIcon from '@assets/timer.svg';
import * as styles from './UnconfirmedEmailMessage.module.scss';


export default function UnconfirmedEmailMessage() {
  const { user } = useUser();
  const countDown = useCountDown();

  useEmailConfirmation(true);

  const handleResendClick = React.useCallback(async () => {
    try {
      countDown?.start();
      await api.resendEmailConfirmation();
    } catch (error) {
      toast.error(error.message);
    }
  }, [countDown]);

  if (user && user.isEmailConfirmed) {
    return null;
  }

  return (
    <Message
      type="warning"
      className={styles.container}
      contentClassName={styles.content}
    >
      <div className={styles.message}>Please, confirm your email address.</div>
      {countDown?.isActive && (
        <span className={styles.counter}>
          <span>{countDown.count}s</span>
          <TimerIcon className={styles.counter__icon}/>
        </span>
      )}
      <button
        type="button"
        className={clsx('button small light', styles.button)}
        disabled={countDown?.isActive}
        onClick={handleResendClick}
      >
        <span>Resend link</span>
      </button>
    </Message>
  );
}
