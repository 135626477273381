import * as React from 'react';
import { formatDate } from '@core/utils';
import { CART } from '@core/constants';
import Collapsible from '@components/Collapsible';
import EntitiesList from '@components/EntitiesList';
import Price from '@components/Price';
import ProductRow from '@components/store/ProductRow';
import { AnyObject, Order, OrderItem } from '@interface/common';
import { Product, Bundle } from '@interface/gatsby';
import * as styles from './OrderRow.module.scss';


type OrderProductRowProps = {
  item: OrderItem;
  products: AnyObject<Product>;
  bundles: AnyObject<Bundle>;
}

const OrderProductRow = ({ item, products, bundles }: OrderProductRowProps) => {
  const [expanded, setExpanded] = React.useState<boolean>(false);

  let props: any = {
    price: item.finalPrice,
    showDiscount: false,
  };
  if (item.bundleId) {
    props = { ...props, ...item, ...bundles[item.bundleId], type: CART.ITEM.BUNDLES, ids: item.products };
  } else {
    props = { ...props, ...products[item.products[0]], type: CART.ITEM.PRODUCTS };
  }

  const handleExpand = (_id: string) => setExpanded(prevState => !prevState);

  return (
    <ProductRow
      {...props}
      className={styles.row}
      products={products}
      expanded={expanded}
      onExpand={handleExpand}
    />
  );
};


type OrderRowProps = Pick<Order, 'id' | 'createdAt' | 'total'> & {
  ids: string [];
  entities: AnyObject<OrderItem>;
  products: AnyObject<Product>;
  bundles: AnyObject<Bundle>;
}

export default function OrderRow({
  id,
  createdAt,
  total,
  ids,
  entities,
  products,
  bundles
}: OrderRowProps) {
  return (
    <Collapsible
      triggerTagName="div"
      className={styles.container}
      triggerClassName={styles.trigger}
      contentClassName={styles.content}
      title={() => (
        <>
          <span>
            <span className={styles.trigger__title}>Order id</span>
            <span className={styles.trigger__value}>{`#${id}`}</span>
          </span>
          <span>
            <span className={styles.trigger__title}>Placed at</span>
            <span className={styles.trigger__value}>{formatDate(createdAt, true)}</span>
          </span>
          <span className={styles.trigger__total}>
            <span className={styles.trigger__title}>Total</span>
            <span className={styles.trigger__value}><Price value={total} showCurrency/></span>
          </span>
        </>
      )}>
      <EntitiesList
        ids={ids}
        entities={entities}
        render={({ item }) => (
          <OrderProductRow
            item={item}
            products={products}
            bundles={bundles}
          />
        )}
      />
    </Collapsible>
  );
}
