import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useRecoilValue } from 'recoil';
import Select, { components, SelectInstance, CommonProps, SingleValue, GroupBase } from 'react-select';
import clsx from 'clsx';
import { libraryStatsState, updatesStatsState } from '@core/atoms';
import { FILTER } from '@core/constants';
import { useDebouncedValue } from '@core/hooks';
import DropdownIndicator from '@components/forms/select/DropdownIndicator';
import PlusIcon from '@assets/plus.svg';
import SearchIcon from '@assets/search.svg';
import { PostgresData, ProductCategory } from '@interface/gatsby';
import * as clearIndicatorStyles from '@components/forms/select/ClearIndicator.module.scss';
import * as styles from './LibraryFilter.module.scss';


type FilterOption = {
  slug: string;
  title: string;
}

type ResetFilterIndicatorProps = CommonProps<FilterOption, false, GroupBase<FilterOption>> & {
  selectRef: React.RefObject<SelectInstance<FilterOption, false, GroupBase<FilterOption>>>
}

const ResetFilterIndicator = ({ selectRef, ...props }: ResetFilterIndicatorProps) => {
  if (props.getValue()[0].slug === FILTER.SHOW_ALL) {
    return null;
  }

  const onMouseDown = (event: React.MouseEvent) => {
    if (event && event.type === 'mousedown' && event.button !== 0) {
      return;
    }

    // @ts-ignore
    const defaultOption: Option = props.options[0];

    props.selectOption(defaultOption);
    event.preventDefault();

    if (event.type === 'touchend') {
      selectRef.current?.focus();
    } else {
      setTimeout(() => selectRef.current?.focus(), 0);
    }
  };

  const onTouchEnd = function (event: React.MouseEvent) {
    if (selectRef.current?.userIsDragging) return;
    onMouseDown(event);
  };

  const innerProps = {
    onMouseDown,
    onTouchEnd,
    'aria-hidden': 'true',
  };
  return (
    // @ts-ignore
    <components.ClearIndicator<FilterOption, false>
      {...props}
      className={clsx('form__fieldReset', clearIndicatorStyles.button)}
      innerProps={innerProps}
    >
      <PlusIcon/>
    </components.ClearIndicator>
  );
};


type Props = {
  show: 'all' | string;
  search: string;
  onFilterChange: (str: string) => void;
  onSearchChange: (str: string) => void;
}

export default function LibraryFilter({ show, search, onFilterChange, onSearchChange }: Props) {
  const { postgres } = useStaticQuery<PostgresData<Record<'categories', ProductCategory[]>>>(graphql`
      query LibraryProductCategoryFilter {
          postgres {
              categories: productCategoriesList(orderBy: SORT_ORDER_ASC) {
                  id
                  title
                  slug
              }
          }
      }
  `);
  const library = useRecoilValue(libraryStatsState);
  const updates = useRecoilValue(updatesStatsState);
  const showAll: FilterOption = {
    slug: FILTER.SHOW_ALL,
    title: 'All',
  };
  const filterOptions: FilterOption[] = [showAll, ...postgres.categories];
  const filterDefaultValue = filterOptions.find(o => o.slug === show);

  const [searchInput, setSearchInput] = React.useState<string>(search);
  const showRef = React.createRef<SelectInstance<FilterOption, false, GroupBase<FilterOption>>>();

  useDebouncedValue<string>(searchInput, {
    skipOnInit: true,
    delay: 350,
    callback: onSearchChange,
  });

  React.useEffect(() => {
    if (search !== searchInput) {
      setSearchInput(search);
    }
  }, [search]);

  const getFilterOptionLabel = (option: FilterOption) => option.title;
  const getFilterOptionValue = (option: FilterOption) => option.slug;

  const handleFilterChange = (option: SingleValue<FilterOption>) => option?.slug && onFilterChange(option.slug);
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => setSearchInput(event.target.value);

  return (
    <section className={styles.container}>
      <Select<FilterOption, false>
        className={styles.filter__select}
        classNamePrefix="select"
        id="show"
        name="show"
        isClearable
        isSearchable={false}
        backspaceRemovesValue={false}
        value={filterDefaultValue}
        options={filterOptions}
        getOptionLabel={getFilterOptionLabel}
        getOptionValue={getFilterOptionValue}
        onChange={handleFilterChange}
        components={{
          ClearIndicator: (props) => <ResetFilterIndicator {...props} selectRef={showRef}/>,
          IndicatorSeparator: undefined,
          DropdownIndicator,
        }}
        ref={showRef}
      />
      {!library.isEmpty && updates.isFetching && (
        <div className={styles.updating}>Checking for updates...</div>
      )}
      <div className={styles.search}>
        <input
          className={clsx('form__fieldInput', styles.search__input)}
          type="search"
          name="search"
          value={searchInput}
          placeholder="Search..."
          onChange={handleSearchChange}
        />
        <div className="form__pseudo"/>
        <SearchIcon className={styles.search__icon}/>
      </div>
    </section>
  );
}
