import * as React from 'react';
import clsx from 'clsx';
import { passwordStrength, DiversityType } from 'check-password-strength';
import PasswordDiversity from '@core/forms/password_diversity';
import BaseInput from './BaseInput';
import LockedIcon from '@assets/fingerprint-closed.svg';
import UnlockedIcon from '@assets/fingerprint-opened.svg';
import CheckmarkIcon from '@assets/checkmark.svg';
import MinusIcon from '@assets/minus.svg';
import { FieldRenderProps } from '@interface/common';
import * as styles from './PasswordInput.module.scss';


// const BAR_COLORS = ['#292929', '#ef4836', '#f6b44d', '#2b90ef', '#25c281'];

const DEFAULT_BAR_COLOR = 'rgb(35, 35, 35)';
const FOCUS_BAR_COLOR = 'rgb(41, 41, 41)';

const BAR_COLORS = [
  DEFAULT_BAR_COLOR,
  'rgb(197, 57, 80)',
  'rgb(249, 105, 14)',
  'rgb(100, 149, 237)',
  'rgb(37, 194, 129)',
];

type BarItemProps = {
  score: number;
  itemNum: number;
  barColors: string[];
}

function BarItem({ score, itemNum, barColors }: BarItemProps) {
  let bgColor = barColors[0];
  if (score >= itemNum) {
    bgColor = barColors[score];
  }

  return (
    <div
      className={styles.bar__item}
      style={{
        backgroundColor: bgColor,
      }}
    />
  );
}

type HintItemProps = {
  condition: boolean;
  text: string;
}

function HintItem({ condition, text }: HintItemProps) {
  return (
    <p className={styles.hint__row}>
      <span>
        {condition ?
          <CheckmarkIcon className={clsx(styles.hint__icon, styles.success)}/> :
          <MinusIcon className={clsx(styles.hint__icon, styles.failure)}/>
        }
      </span>
      <span className={styles.hint__text}>{text}</span>
    </p>
  );
}

type PasswordInputProps = FieldRenderProps<string> & {
  useMeter?: boolean;
  useHint?: boolean;
  useToggle?: boolean;
}

export default function PasswordInput({
  input,
  meta,
  id,
  label,
  required,
  minLength = 8,
  maxLength = 255,
  autoComplete = 'off',
  useMeter = false,
  useHint = false,
  useToggle = false,
  ...props
}: PasswordInputProps) {
  const componentId = id || React.useId();
  const [isShown, setIsShown] = React.useState(false);
  const [colors, setColors] = React.useState(BAR_COLORS);
  const feedback = passwordStrength<string>(input.value, PasswordDiversity);

  const checkDiversity = (diversity: DiversityType) => feedback.contains.includes(diversity);
  const changeColor = (color: string) => setColors(([, ...rest]) => [color, ...rest]);

  const handleInputFocus = (event: React.FocusEvent<HTMLElement>) => {
    changeColor(FOCUS_BAR_COLOR);
    input.onFocus(event);
  };
  const handleInputBlur = (event: React.FocusEvent<HTMLElement>) => {
    changeColor(DEFAULT_BAR_COLOR);
    input.onBlur(event);
  };

  const handleShowHideClick = () => setIsShown((state => !state));

  return (
    <BaseInput
      id={componentId}
      label={label}
      meta={meta}
      required={required}
      renderSubLabel={() => useMeter && (
        <span className="form__fieldSubLabel">
          {feedback.value}
        </span>
      )}
      renderFooter={() => (
        <>
          {useMeter && (
            <div className={styles.bar}>
              {[1, 2, 3, 4].map((el: number) => (
                <React.Fragment key={`${componentId}-${el}`}>
                  {el > 1 && <div className={styles.bar__spacer}/>}
                  <BarItem
                    score={feedback.id}
                    itemNum={el}
                    barColors={colors}
                  />
                </React.Fragment>
              ))}
            </div>
          )}
          {useHint && (
            <div className={clsx('form__fieldHint', styles.hint)}>
              <HintItem
                condition={feedback.length >= minLength}
                text="At least 8 characters."
              />
              <HintItem
                condition={checkDiversity('lowercase') && checkDiversity('uppercase')}
                text="A mix of uppercase and lowercase latin letters."
              />
              <HintItem
                condition={checkDiversity('number')}
                text="Contains numbers."
              />
              <HintItem
                condition={checkDiversity('symbol')}
                text="Contains special characters."
              />
            </div>
          )}
        </>
      )}
    >
      <input
        {...input}
        {...props}
        className={clsx('form__fieldInput', styles.input)}
        id={componentId}
        type={isShown ? 'text' : 'password'}
        autoComplete={isShown ? 'off' : autoComplete}
        maxLength={maxLength}
        required={required}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
      />
      <span className="form__pseudo"/>
      {useToggle && (
        <button
          className={styles.toggle}
          type="button"
          tabIndex={-1}
          aria-label="Toggle password visibility"
          title={isShown ? 'Hide' : 'Show'}
          onClick={handleShowHideClick}
        >
          {isShown ?
            <UnlockedIcon className={styles.toggle__icon}/> :
            <LockedIcon className={styles.toggle__icon}/>
          }
        </button>
      )}
    </BaseInput>
  );
}
